import { API_VENDOR_URL } from "../../config/constants";
import { postRequest,fetchIpAddress } from "../API";

/*********************************************************
* Function Name : List
* Description   : Get list of all service category
* By            : Md RAshid Ali
* Date          : 26-02-2024 
*********************************************************/
export const list = async (options) =>{
    try {
        const params = {
            url : `${API_VENDOR_URL}services/request/list`, 
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : request
* Description   : create new request 
* By            : Afsar Ali 
* Date          : 27-03-2024 
*********************************************************/
export const request = async (options) =>{
    try {
        const params = {
            url : `${API_VENDOR_URL}services/request`, 
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : bulkRequest
* Description   : create new bulk request 
* By            : Afsar Ali 
* Date          : 10-08-2024 
*********************************************************/
export const bulkRequest = async (options) =>{
    try {
        const params = {
            url : `${API_VENDOR_URL}services/request/bulk-upload`, 
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function