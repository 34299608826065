import  { API_VENDOR_URL }  from './../config/constants';
import {getRequest, postRequest} from './API';

/*********************************************************
* Function Name : productlist
* Description   : Get list of all products category
* By            : Afsar Ali
* Date          : 09-02-2024 
*********************************************************/
export const productlist = async (options) =>{
    try {
        const params = {
            url : `${API_VENDOR_URL}categories/product-categories`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : servicetlist
* Description   : Get list of all servce category
* By            : Afsar Ali
* Date          : 09-02-2024 
*********************************************************/
export const servicetlist = async (options) =>{
    try {
        const params = {
            url : `${API_VENDOR_URL}categories/service-categories`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : requestCategory
* Description   : Request Service/Product Category
* By            : Afsar Ali
* Date          : 10-02-2024 
*********************************************************/
export const requestCategory = async (options) =>{
    try {
        const params = {
            url : `${API_VENDOR_URL}categories/request-categories`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function
/*********************************************************
* Function Name : updateProductCategoryStatus
* Description   : Update the status of a product category
* By            : Siddharth Singh
* Date          : 13/08/2024
*********************************************************/
export const updateProductCategoryStatus = async (options) => {
    try {
        const params = {
            url: `${API_VENDOR_URL}categories/product-categories/update-status`, 
            postData:  options
        };
        const res = await postRequest(params);
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage };
        }
    } catch (error) {
        console.log(error);
        return { status: false, message: "Under Maintenance, Please try after some time." };
    }
};
/*********************************************************
* Function Name : updateServiceCategoryStatus
* Description   : Update the status of a product category
* By            : Siddharth Singh
* Date          : 13/08/2024
*********************************************************/
export const updateServiceCategoryStatus = async (options) => {
    try {
        const params = {
            url: `${API_VENDOR_URL}categories/service-categories/update-status`, 
            postData:  options
        };
        const res = await postRequest(params);
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage };
        }
    } catch (error) {
        console.log(error);
        return { status: false, message: "Under Maintenance, Please try after some time." };
    }
};
