import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React, { useState, useEffect, useRef, lazy, Suspense } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { FaFilter } from "react-icons/fa";
import { Dropdown, Menu, Switch, Flex, Spin, Drawer, Button, Space } from "antd";
import { FaRegCommentDots } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Pagination from "@mui/material/Pagination";
import { Link, useNavigate } from "react-router-dom";
import { IoNotificationsCircleSharp } from "react-icons/io5";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { FaPlusSquare } from "react-icons/fa";
import ServicesTopNavbar from "./ServicesTopNavbar";
import Top_navbar from "../DashBaord/Top_navbar";
import ServicesRemovePopUp from "./ServicesDeletePopup";

import AddRequestPopUp from "./AddRequestPopUp";

import moment from "moment";
import { ASSETS_BASE_URL } from "../../config/constants";
import SkeltonList from "../../components/SkeltonEffect/list";
import { serviceList } from "../../controllers/services/servicesController";
import { getPage, RequestStatusMessage } from '../../controllers/common';

import GroomingList from './Grooming/List';
import TrainingList from './Training/List';
import VeterinaryList from './Veterinary/List';
import BoardingList from './Boarding/List';
import DayCareList from './DayCare/List';
import BulkUpload from "./BulkUpload";

function Services(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

Services.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [bulkUploadPopUp, setBulkUploadPopUp] = useState(false);
  const handleBulkUploadPopUp = () => {
    setBulkUploadPopUp(!bulkUploadPopUp);
  };

  /************************************************************************************* */
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [ALLSERVICES, setAllServices] = useState([]);
  const [SERVICES, setServices] = useState("");
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from: '',
    to: new Date(),
    filter_by: "",
    search: ""
  })//End

  const [showRequest, setShowRequest] = useState("");
  const [isFilterShow, setFilterShow] = useState(false);
  const handleFiterDrawer = () => {
    setFilterShow(!isFilterShow);
  };

  const getVendorServices = async () => {
    try {
      setAllServices([]);
      setIsLoading(true);
      const options = {
        type: "",
        conditon: {
          ...(showRequest ? { status: showRequest } : null)
        },
        ...(filter ? { filter: filter } : null),
        select: {},
        sort: { "_id": -1 },
        skip: SKIP ? SKIP : 0,
        limit: LIMIT ? LIMIT : 10
      }
      const listData = await serviceList(options);
      if (listData.status === true) {
        if (listData.result.length > 0) {
          setAllServices(listData?.result);
          setServices(listData?.result[0]?.name);
          setTotalPages(getPage(listData?.count));
        } else {
          setAllServices([{ vendor_first_name: 'No Data Found' }]);
          setTotalPages(getPage(0));
        }
      }
      const countOptions = {
        type: "count",
        condition: { ...(showRequest ? { status: showRequest } : null) },
        ...(filter ? { filter: filter } : null)
      };
      const count = await serviceList(countOptions);
      setTotalPages(getPage(count?.result));
      setIsLoading(false);
    } catch (error) {
      setAllServices([]);
      setIsLoading(false);
    }
  };


  /*********************************************************
  *  This function is for handle page change
  *********************************************************/
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setSkip((newPage - 1) * LIMIT);
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  };

  /*********************************************************
*  This function is ued for handle filter input chnage
*********************************************************/
  const handleFilterReset = () => {
    try {
      setFilter('');
      inputRef1.current.value = '';
      inputRef2.current.value = '';
      inputRef3.current.value = '';
      inputRef4.current.value = '';
    } catch (error) {

    }
  }

  const handleFilterApply = (e) => {
    try {
      e.preventDefault();
      const form = new FormData(e.target);
      setFilter((pre) => ({
        ...pre,
        'filter_by': form.get('filter_by') || '',
        'search': form.get('search') || '',
        'to': form.get('to_date') || '',
        'from': form.get('from_date') || '',

      }))
      handleFiterDrawer();
    } catch (error) {

    }
  }

  /*********************************************************
  *  This function is load when page load and with dependency update
  *********************************************************/
  useEffect(() => {
    getVendorServices();
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    })
    document.title = 'Frisbee Vendor Admin || Services List'
  }, []);

  return (
    <>
      <div className="container-fluid" ref={targetRef}>
        <Top_navbar title={`Services List`}></Top_navbar>
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="item_tabs_main_container"
                  >
                    {ALLSERVICES && ALLSERVICES.map((item, index) => (
                      item.name !== 'Shop' ? (
                        <Tab
                          key={index} // Key should be assigned to the outermost JSX element in the map function
                          label={`${item?.name}`}
                          className="tabs_main_contnet"
                          id={`simple-tab-${index}`}
                          aria-controls={`simple-tabpanel-${index}`}
                          onClick={() => setServices(item?.name)}
                        />
                      ) : (<></>)
                    ))}
                  </Tabs>
                </div>
                <div className="fa_filter_rating_review" >
                  <div className="inventory_filter_div" onClick={handleFiterDrawer} >
                    <img
                      src={ASSETS_BASE_URL + "./images/Filter.svg"}
                      alt=""
                      className="user_iconses"
                    />

                    <Link to={false} className="filter_btn_invontry_rating_review">
                      Filters
                    </Link>
                  </div>

                  <div className="inventory_filter_div" onClick={handleBulkUploadPopUp} >
                    <button
                      type="button"
                      class="btn btn-dark"
                      // onClick={handleBulkUploadPopUp}
                    >
                      <UploadOutlined className="Promotions_faPlus_Squares" />
                      <span className="request_new_categories">Bulk Request</span>
                    </button>
                  </div>

                </div>
              </div>
            </Box>
            <Suspense fallback={<div>Loading...</div>}>
              {SERVICES === 'Grooming' && <GroomingList />}
              {SERVICES === 'Training' && <TrainingList />}
              {SERVICES === 'Veterinary' && <VeterinaryList />}
              {SERVICES === 'Boarding' && <BoardingList />}
              {SERVICES === 'Day Care' && <DayCareList />}
            </Suspense>
          </Box>
        </div>

        <Flex align="center" gap="middle">
          <Spin
            size="large"
            spinning={isLoading}
            fullscreen={true}
          />
        </Flex>
      </div>

      <BulkUpload
        show={bulkUploadPopUp}
        setBulkUploadPopUp={setBulkUploadPopUp}
        service = {SERVICES}
      />

      {/* Filter */}
      <Drawer
        title="Inventory Filter"
        placement={`right`}
        width={500}
        onClose={handleFiterDrawer}
        open={isFilterShow}
        extra={
          <Space>
            <Button onClick={handleFiterDrawer}>Cancel</Button>
            <Button type="primary" onClick={handleFilterReset}>
              Reset
            </Button>
          </Space>
        }
      >
        <div className="responsive">
          <div className="row">
            <form id='filter_form' onSubmit={handleFilterApply}>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_lable_for_vendor_dashbaord" >
                  Search Field :
                </label>
                <select name="filter_by" className="form-control store_input_field_field" id="filter_by" ref={inputRef1}>
                  <option value=''>Select search field</option>
                  <option value='product_name' selected={filter?.filter_by === 'product_name' ? true : false} >Product Name</option>
                  <option value='store_name' selected={filter?.filter_by === 'store_name' ? true : false}>Store Name</option>
                  <option value='store_phone' selected={filter?.filter_by === 'store_phone' ? true : false}>Store Phone</option>
                  <option value='store_email' selected={filter?.filter_by === 'store_email' ? true : false}>Store Email</option>
                  <option value='category' selected={filter?.filter_by === 'category' ? true : false}>Category</option>
                  <option value='subcategory' selected={filter?.filter_by === 'subcategory' ? true : false}>Sub-Category</option>
                  <option value='status' selected={filter?.filter_by === 'status' ? true : false}>Status</option>
                </select>
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_lable_for_vendor_dashbaord" >
                  Search Text :
                </label>
                <input
                  title="Enter search text."
                  placeholder="Enter search text."
                  type="text"
                  name="search"
                  className="form-control store_input_field_field"
                  id="search"
                  ref={inputRef2}
                // onChange={handleFilterChnage} 
                // value={filter?.search}             
                />
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_lable_for_vendor_dashbaord" >
                  From :
                </label>
                <input
                  title="Enter search text."
                  type="date"
                  name="from_date"
                  className="form-control store_input_field_field"
                  id="from_date"
                  ref={inputRef3}
                // onChange={handleFilterChnage}          
                />
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_lable_for_vendor_dashbaord" >
                  To :
                </label>
                <input
                  title="Enter search text."
                  type="date"
                  name="to_date"
                  className="form-control store_input_field_field"
                  id="to_date"
                  ref={inputRef4}
                // onChange={handleFilterChnage}             
                />
              </div>
              <br />
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button
                  title="Enter search text."
                  type="submit"
                  className="form-control btn btn-primary "
                  style={{ backgroundColor: "black", color: "white",border:"none" }}
                >Apply</button>
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    </>
  );
}
