import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React, { useEffect, useState, useRef } from "react";
import {
  Dropdown,
  Menu,
  Switch,
  notification,
  Drawer,
  Button,
  Space,
} from "antd";
import { FaEdit, FaPlusSquare } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Pagination from "@mui/material/Pagination";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import TobNavBar from "../../DashBaord/Top_navbar";
import { Link, useNavigate } from "react-router-dom";
import { chnageStatus, list } from "../../../controllers/Products/product";
import { ASSETS_BASE_URL } from "../../../config/constants";
import moment from "moment";
import { getPage } from "../../../controllers/common";
import ProductListDeletePopUp from "./ProductListDeletePopUp";
import Skeltonlist from "../../../components/SkeltonEffect/list";
import "./ProductList.css";
import ShowVarientsPopUP from "../showVarientsPopUp";

import BulkUploadModel from "./bulkUploadPopUp";

function ProductList(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

ProductList.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [varientToggleProductPopUp, setvarientToggleProductPopUp] =
    useState(false);
  const [VarientData, setVarientData] = useState("");
  const opentoggleVarient = (item) => {
    setvarientToggleProductPopUp(!varientToggleProductPopUp);
    setVarientData(item);
  };
  const navigate = useNavigate();
  //BulkUploadModel
  const [showBulkUpload, setShowBulkUpload] = useState(false);
  const openBulkUploadPopUp = () => {
    setShowBulkUpload(!showBulkUpload);
  };
  //End
  // delete PopUp
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  const [EDITDATA, setEditData] = useState([]);
  const handleAddEditData = () => {
    navigate("/product/list/edit", { state: EDITDATA });
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [isFilterShow, setFilterShow] = useState(false);
  const handleFiterDrawer = () => {
    setFilterShow(!isFilterShow);
  };
  const dropdownMenu = (
    <Menu>
      <Menu.Item key="1">
        <FaEdit />
        <span className="show_span_edit" onClick={handleAddEditData}>
          Edit Product List
        </span>
      </Menu.Item>
      <Menu.Item key="2" onClick={togglePopUp}>
        <MdDelete /> <span className="show_span">Delete</span>
      </Menu.Item>
    </Menu>
  );

  /*********************************************************
   *  This function is use to handle notification
   *********************************************************/
  const handleNotification = (status, msg) => {
    notification.open({
      message: status,
      description: msg,
      placement: "topRight",
      duration: 2,
    });
  }; //End
  /*********************************************************
   *  This function is use to fetch category list
   *********************************************************/
  const [deteteId, setDeteteId] = useState("");
  const targetRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from: "",
    to: "",
    filter_by: "",
    search: "",
  }); //End
  const [showRequest, setShowRequest] = useState("");
  const getList = async () => {
    setListData([]);
    const options = {
      type: "",
      condition: {
        ...(showRequest ? { status: showRequest } : null),
      },
      ...(filter ? { filter: filter } : null),
      select: {},
      sort: { _id: -1 },
      populate: {
        key: "category subcategory",
        select: "name",
      },
      skip: SKIP ? SKIP : 0,
      limit: LIMIT ? LIMIT : 10,
    };
    const listData = await list(options);
    if (listData.status === true) {
      setListData(listData?.result);
      const countOptions = {
        type: "count",
        condition: { ...(showRequest ? { status: showRequest } : null) },
        ...(filter ? { filter: filter } : null),
      };
      const count = await list(countOptions);
      setTotalPages(getPage(count?.result));
    } else {
      setListData([]);
    }
  }; //End
  /*********************************************************
   *  This function is use to handle chnage status
   *********************************************************/
  const handleStatus = async (item) => {
    const option = {
      url: `productsmaster/change-product-status`,
      postData: {
        product_id: item?._id,
        status: item.status === "A" ? "I" : "A",
      },
    };
    const res = await chnageStatus(option);
    if (res.status === true) {
      getList();
      handleNotification("Success!", "Status Updated.");
    } else {
      handleNotification("Error!", res?.message);
    }
  }; //End

  /*********************************************************
   *  This function is for handle page change
   *********************************************************/
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setSkip((newPage - 1) * LIMIT);
    targetRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  /*********************************************************
   *  This function is ued for handle filter input chnage
   *********************************************************/
  const handleFilterReset = () => {
    try {
      setFilter({ from: "", to: "", filter_by: "", search: "" });
      inputRef1.current.value = "";
      inputRef2.current.value = "";
      inputRef3.current.value = "";
      inputRef4.current.value = "";
    } catch (error) {
      console.error("Error resetting filter:", error);
    }
  };

  const handleFilterApply = (e) => {
    try {
      e.preventDefault();
      const form = new FormData(e.target);
      setFilter({
        filter_by: form.get("filter_by") || "",
        search: form.get("search") || "",
        to: form.get("to_date") || "",
        from: form.get("from_date") || "",
      });
      handleFiterDrawer();
    } catch (error) {
      console.error("Error applying filter:", error);
    }
  };
  // const handleFilterChange = (e) => {
  //   const { name, value } = e.target;
  //   setFilter((prevFilter) => ({
  //     ...prevFilter,
  //     [name]: value,
  //   }));
  // };
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };
  /*********************************************************
   *  This function is load when page load and with dependency update
   *********************************************************/
  useEffect(() => {
    getList();
    targetRef.current.scrollIntoView({
      behavior: "smooth",
    });
  }, [currentPage, showRequest, filter]);

  return (
    <>
      <div className="container-fluid" ref={targetRef}>
        <TobNavBar title={`Product List`}></TobNavBar>
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="item_tabs_main_container"
                  >
                    <Tab
                      label="All"
                      className="tabs_main_contnet"
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="New"
                      className="tabs_main_contnet"
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </div>

                <div className="inventory_filter_div d-flex filters_aall_text_data">
                  <div
                    className="filter-text d-flex"
                    onClick={handleFiterDrawer}
                  >
                    <img
                      src={ASSETS_BASE_URL + "./images/Filter.svg"}
                      alt=""
                      className="filter_iconsm"
                    />

                    <Link
                      to={false}
                      className="filter_btn_invontry_rating_review filter_anchor_text"
                    >
                      Filters
                    </Link>
                  </div>
                </div>
              </div>
            </Box>
            {/* First Inventory */}
            <ProductList value={value} index={0}>
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 all_border_bottom_for_hide">
                      <h6 className="m-0 font-weight-bold common_inventory_heading">
                        Product List
                      </h6>
                    </div>
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Image</th>
                              <th>Category</th>
                              <th>Sub Category</th>
                              <th>Varients</th>
                              <th>Date&Time</th>
                              <th>Status</th>
                              {/* <th>Actions</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {ALLLISTDATA.length > 0 ? (
                              ALLLISTDATA.map((items, index) => (
                                <>
                                  <tr key={index}>
                                    <td>
                                      <div className="admin_prodcut_list_span">
                                        <img
                                          src={`${ASSETS_BASE_URL}${items?.productData?.image}`}
                                          alt=""
                                        />
                                        <span className="admin_prodcut_list_span_name">
                                          {items?.productData?.name}
                                        </span>
                                      </div>
                                    </td>

                                    <td className="admin_user_list_date">
                                      {items?.productData?.category?.name}
                                    </td>
                                    <td className="admin_user_list_date">
                                      {items.productData.subcategory.name}
                                    </td>
                                    <td className="admin_user_list_date">
                                      {items?.varients.length}{" "}
                                      <button
                                        className="btn-checkk"
                                        onClick={() => {
                                          opentoggleVarient(items);
                                        }}
                                      >
                                        check
                                      </button>
                                    </td>
                                    <td className="admin_user_list_date">
                                      {moment(`${items.createdAt}`).format(
                                        "DD/MM/YYYY, h:m A"
                                      )}
                                    </td>
                                    <td>
                                      <Switch
                                        className="categories_switch_"
                                        checked={items?.status === "A"}
                                        onClick={() => handleStatus(items)}
                                      />
                                    </td>
                                  </tr>
                                </>
                              ))
                            ) : (
                              <Skeltonlist row={10} col={7} />
                            )}
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination
                            count={TOTALPAGES}
                            onChange={handlePageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ProductList>
            {/* Third Inventory */}
          </Box>
        </div>
        <ProductListDeletePopUp
          popUpOpen={popUpOpen}
          EDITDATA={EDITDATA}
          togglePopUp={togglePopUp}
        />
        <ShowVarientsPopUP
          varientToggleProductPopUp={varientToggleProductPopUp}
          opentoggleVarient={opentoggleVarient}
          VarientData={VarientData}
        />

        <BulkUploadModel
          show={showBulkUpload}
          openBulkUploadPopUp={openBulkUploadPopUp}
        />
      </div>
      {/* Filter */}
      <Drawer
        title="Product Filter"
        placement={`right`}
        width={500}
        onClose={handleFiterDrawer}
        open={isFilterShow}
        extra={
          <Space>
            <Button onClick={handleFiterDrawer}>Cancel</Button>
            <Button type="primary" onClick={handleFilterReset}>
              Reset
            </Button>
          </Space>
        }
      >
        <div className="responsive">
          <div className="row">
            <form id="filter_form" onSubmit={handleFilterApply}>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label
                  htmlFor="vendor_first_name"
                  className="all_lable_for_vendor_dashbaord"
                >
                  Search Field :
                </label>
                <select
                  name="filter_by"
                  className="form-control store_input_field_field"
                  id="filter_by"
                  ref={inputRef1}
                >
                  <option value="">Select search field</option>
                  <option
                    value="name"
                    selected={filter?.filter_by === "name" ? true : false}
                  >
                    Name
                  </option>
                  <option
                    value="category"
                    selected={filter?.filter_by === "category" ? true : false}
                  >
                    Category
                  </option>
                  <option
                    value="name"
                    selected={filter?.filter_by === "name" ? true : false}
                  >
                    Sub Category
                  </option>
                  <option
                    value="status"
                    selected={filter?.filter_by === "status" ? true : false}
                  >
                    Status
                  </option>
                </select>
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label
                  htmlFor="vendor_first_name"
                  className="all_lable_for_vendor_dashbaord"
                >
                  Search Text :
                </label>
                <input
                  title="Enter search text."
                  placeholder="Enter search text."
                  type="text"
                  name="search"
                  className="form-control store_input_field_field"
                  id="search"
                  ref={inputRef2}
                  // onChange={handleFilterChnage}
                />
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label
                  htmlFor="vendor_first_name"
                  className="all_lable_for_vendor_dashbaord"
                >
                  From :
                </label>
                <input
                  title="Enter search text."
                  type="date"
                  name="from_date"
                  className="form-control store_input_field_field"
                  id="from_date"
                  ref={inputRef3}
                  // onChange={handleFilterChnage}
                />
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label
                  htmlFor="vendor_first_name"
                  className="all_lable_for_vendor_dashbaord"
                >
                  To :
                </label>
                <input
                  title="Enter search text."
                  type="date"
                  name="to_date"
                  className="form-control store_input_field_field"
                  id="to_date"
                  ref={inputRef4}
                  // onChange={handleFilterChnage}
                />
              </div>
              <br />
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button
                  title="Enter search text."
                  type="submit"
                  className="form-control btn btn-primary "
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    border: "none",
                  }}
                >
                  Apply
                </button>
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    </>
  );
}
