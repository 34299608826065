import {API_VENDOR_URL} from '../../config/constants'
import { postRequest } from '../API'

/*********************************************************
* Function Name : NotificationsList
* Description   : Get list of all NotificationsList
* By            : Noor Alam
* Date          : 14May-2024 
*********************************************************/
export const NotificationsList = async (options) =>{
    try {
        const params = {
            url : `${API_VENDOR_URL}notifications/list`, 
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result, count: res?.data?.response?.count};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function



/*********************************************************
* Function Name : chnageStatus
* Description   : This function is used for chnage prmomo code status
* By            : Noor Alam
* Date          : 14May-2024 
*********************************************************/
// export const chnageStatus = async (options) =>{
//     try {
//         const params = {
//             url : `${API_VENDOR_URL}insights/change-status`,
//             postData : {
//                 ...options,
//                 ipAddress : sessionStorage.getItem('IP_ADDRESS')
//             }
//         }
//         const res = await postRequest(params);
//         if(res.status === true || res.status === 200){
//             return {status : true, result : res?.data?.response?.result};
//         } else{
//             return {status : false, message:res?.response?.data?.statusMessage}
//         }
//     } catch (error) {
//         return {status : false, message:"Under Maintenance, Please try after some time."}
//     }
// };//End of Function


/*********************************************************
* Function Name : deleteNotifications
* Description   : This function is used for chnage prmomo code status
* By            : Noor Alam
* Date          : 14May-2024 
*********************************************************/
export const deleteNotifications = async (options) =>{
    const { url, postData={} } = options;
    try {
        const params = {
            url : `${API_VENDOR_URL}${url}`,
            postData : {
                ...postData,
                ipAddress : sessionStorage.getItem('IP_ADDRESS')
            }
        }
        const res = await postRequest(params);
        console.log("deleteNotifications res", res)
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function
