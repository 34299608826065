import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { FaFilter } from "react-icons/fa";
import { Dropdown, Menu, Button, Drawer, Space } from "antd";
import { FaRegCommentDots } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Pagination from "@mui/material/Pagination";
import { Select } from "antd";
// import BookingDeletePopup from "../BookingDeletePopUp";
import "../../Orders/OrderMainContnet.css";
import { IoNotificationsCircleSharp } from "react-icons/io5";
import Top_navbar from "../../DashBaord/Top_navbar";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { ASSETS_BASE_URL } from "../../../config/constants";
import SkeltonList from "../../../components/SkeltonEffect/list";
import { list } from "../../../controllers/bookings/trainingController";
import { getPage, bookingStatus } from "../../../controllers/common";

function BookingList(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

BookingList.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  // delete PopUp
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /************************************************************************************* */
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const targetRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const [deleteId, setDeleteId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from: "",
    to: new Date(),
    filter_by: "",
    search: "",
  }); //End

  const [showRequest, setShowRequest] = useState("");
  const [isFilterShow, setFilterShow] = useState(false);
  const handleFiterDrawer = () => {
    setFilterShow(!isFilterShow);
  };
  /*********************************************************
   *  This function is use to fetch vendor list
   *********************************************************/
  const getList = async () => {
    setListData([]);
    setIsLoading(true);
    const options = {
      type: "",
      condition: {
        ...(showRequest ? { status: showRequest } : null),
      },
      ...(filter ? { filter: filter } : null),
      select: {},
      sort: { _id: -1 },
      skip: SKIP ? SKIP : 0,
      limit: LIMIT ? LIMIT : 10,
    };
    const listData = await list(options);
    if (listData.status === true) {
      if (listData?.result?.length > 0) {
        setListData(listData?.result);
        setIsLoading(false);
      } else {
        // setListData([{ vendor_first_name: "No Data Found" }]);
        setIsLoading(false);
      }
      const countOptions = {
        type: "count",
        condition: { ...(showRequest ? { status: showRequest } : null) },
        ...(filter ? { filter: filter } : null),
      };
      const count = await list(countOptions);
      setTotalPages(getPage(count?.result));
    } else {
      // setListData([{ vendor_first_name: "No Data Found" }]);
      setIsLoading(false);
    }
  }; //End

  /*********************************************************
   *  This function is for handle page change
   *********************************************************/
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setSkip((newPage - 1) * LIMIT);
    targetRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  /*********************************************************
   *  This function is ued for handle filter input chnage
   *********************************************************/
  const handleFilterReset = () => {
    try {
      setFilter("");
      inputRef1.current.value = "";
      inputRef2.current.value = "";
      inputRef3.current.value = "";
      inputRef4.current.value = "";
    } catch (error) {}
  };

  const handleFilterApply = (e) => {
    try {
      e.preventDefault();
      const form = new FormData(e.target);
      setFilter((pre) => ({
        ...pre,
        filter_by: form.get("filter_by") || "",
        search: form.get("search") || "",
        to: form.get("to_date") || "",
        from: form.get("from_date") || "",
      }));
      handleFiterDrawer();
    } catch (error) {}
  };

  /*********************************************************
   *  This function is load when page load and with dependency update
   *********************************************************/
  useEffect(() => {
    getList();
    targetRef.current.scrollIntoView({
      behavior: "smooth",
    });
    document.title = "Frisbee Vendor Admin || Training Booking List";
  }, [currentPage, showRequest, popUpOpen, filter]);

  const handleDetailsShow = (item) => {
    console.log("item", item);
    navigate("/bookings/training/detail", { state: item });
  };

  const dropdownMenu = (item) => (
    <Menu>
      <Menu.Item key="1" onClick={() => handleDetailsShow(item)}>
        <FaEdit />
        <span className="show_span_edit">Details</span>
      </Menu.Item>
      {/* <Menu.Item key="2" onClick={togglePopUp}>
        <MdDelete /> <span className="show_span">Decline</span>
      </Menu.Item> */}
    </Menu>
  );

  //Generare Services String
  const getServicesString = (bookingDetails) => {
    if (!Array.isArray(bookingDetails)) return "";

    let servicesArray = "";

    bookingDetails.forEach((booking, index) => {
      const { services } = booking;
      if (services && services.category && services.name) {
        servicesArray += `<p key=${index}> ${services.name}</p>`;
      }
    });

    return servicesArray;
  };
  //End of Function
  return (
    <>
      <div className="container-fluid" ref={targetRef}>
        <Top_navbar title={`Training Booking List`}></Top_navbar>
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value_rating_review">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="item_tabs_main_container"
                  >
                    <Tab
                      label="All"
                      className="tabs_main_contnet"
                      {...a11yProps(0)}
                      onClick={() => setShowRequest("")}
                    />
                    <Tab
                      label="Pending"
                      className="tabs_main_contnet"
                      {...a11yProps(1)}
                      onClick={() => setShowRequest("Pending")}
                    />
                    <Tab
                      label="Completed"
                      className="tabs_main_contnet"
                      {...a11yProps(2)}
                      onClick={() => setShowRequest("Completed")}
                    />
                    <Tab
                      label="Canceled"
                      className="tabs_main_contnet"
                      {...a11yProps(3)}
                      onClick={() => setShowRequest("Canceled")}
                    />
                    <Tab
                      label="On-Hold"
                      className="tabs_main_contnet"
                      {...a11yProps(3)}
                      onClick={() => setShowRequest("Hold")}
                    />
                  </Tabs>
                </div>

                <div
                  className="filters_inventory_Rating_review"
                  onClick={handleFiterDrawer}
                >
                  <div className="inventory_filter_div">
                    <img
                      src={ASSETS_BASE_URL + "./images/Filter.svg"}
                      alt=""
                      className="user_iconses"
                    />

                    <Link
                      to={false}
                      className="filter_btn_invontry_rating_review"
                    >
                      Filters
                    </Link>
                  </div>
                </div>
              </div>
            </Box>
            {/* First Inventory */}
            <BookingList className="main_container_payments">
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    <div className="card-body pt-0">
                      <div className="table-responsive booking_id_class">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <th>Booking Id</th>
                            <th>User Info</th>
                            <th>Service</th>
                            <th>Service Type</th>
                            <th>Store</th>
                            <td>Amount</td>
                            <th>Status</th>
                            <th>Payment Status</th>
                            <th>Date</th>
                            <th>Actions</th>
                          </thead>
                          <tbody>
                            {ALLLISTDATA?.length > 0 ? (
                              ALLLISTDATA.map((items, index) => (
                                <>
                                  <tr key={index} onDoubleClick={()=>handleDetailsShow(items)}>
                                    <td>{items?.booking_seq_id}</td>
                                    <td>
                                      <span>{items?.userData?.name}</span>
                                      <br />
                                      <span>{items?.userData?.email}</span>
                                      <br />
                                      <span>{items?.userData?.phone}</span>
                                      <br />
                                    </td>
                                    
                                    <td
                                      dangerouslySetInnerHTML={{
                                        __html: getServicesString(
                                          items?.bookingdetails
                                        ),
                                      }}
                                    ></td>
                                    <td> {items?.servive_type}</td>
                                    <td>
                                      <span>{items?.shopId?.shop_name}</span>
                                      <br />
                                      <span>{items?.shopId?.email}</span>
                                      <br />
                                      <span>{items?.shopId?.phone}</span>
                                      <br />
                                    </td>
                                    <td>AED {items?.paidAmount?.toFixed(2)}</td>
                                    <td
                                      dangerouslySetInnerHTML={{
                                        __html: bookingStatus(items?.status),
                                      }}
                                    ></td>
                                    <td>{items?.paymentStatus?.charAt(0).toUpperCase() + items?.paymentStatus?.slice(1).toLowerCase()}</td>
                                    <td>{moment(`${items?.createdAt}`).format("DD/MM/YYYY, hh:mm A")}</td>
                                    <td>
                                      <Dropdown
                                        overlay={() => dropdownMenu(items)}
                                        placement="bottomLeft"
                                        arrow
                                      >
                                        <HiOutlineDotsHorizontal />
                                      </Dropdown>
                                    </td>
                                  </tr>
                                </>
                              ))
                            ) : isLoading ? (
                              <SkeltonList row={5} col={9} />
                            ) : (
                              <tr>
                                <td colspan="5" className="text-center">
                                  <img
                                    src="https://frisbee-llc.s3.me-central-1.amazonaws.com/images/nodata.jpg"
                                    width="55%"
                                  />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination
                            count={TOTALPAGES}
                            onChange={handlePageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </BookingList>
          </Box>
        </div>
        {/* <BookingDeletePopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} /> */}
      </div>
      {/* Filter */}
      <Drawer
        title="Training Booking Filter"
        placement={`right`}
        width={500}
        onClose={handleFiterDrawer}
        open={isFilterShow}
        extra={
          <Space>
            <Button onClick={handleFiterDrawer}>Cancel</Button>
            <Button type="primary" onClick={handleFilterReset}>
              Reset
            </Button>
          </Space>
        }
      >
        <div className="responsive">
          <div className="row">
            <form id="filter_form" onSubmit={handleFilterApply}>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label
                  htmlFor="vendor_first_name"
                  className="all_lable_for_vendor_dashbaord"
                >
                  Search Field :
                </label>
                <select
                  name="filter_by"
                  className="form-control store_input_field_field"
                  id="filter_by"
                  ref={inputRef1}
                >
                  <option value="">Select search field</option>
                  <option
                    value="shop_name"
                    selected={filter?.filter_by === "shop_name" ? true : false}
                  >
                    Store Name
                  </option>
                  <option
                    value="store_phone"
                    selected={
                      filter?.filter_by === "store_phone" ? true : false
                    }
                  >
                    Store Phone
                  </option>
                  <option
                    value="store_email"
                    selected={
                      filter?.filter_by === "store_email" ? true : false
                    }
                  >
                    Store Email
                  </option>
                  <option
                    value="booking_seq_id"
                    selected={
                      filter?.filter_by === "booking_seq_id" ? true : false
                    }
                  >
                    Booking ID
                  </option>
                  <option
                    value="status"
                    selected={filter?.filter_by === "status" ? true : false}
                  >
                    Status
                  </option>
                </select>
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label
                  htmlFor="vendor_first_name"
                  className="all_lable_for_vendor_dashbaord"
                >
                  Search Text :
                </label>
                <input
                  title="Enter search text."
                  placeholder="Enter search text."
                  type="text"
                  name="search"
                  className="form-control store_input_field_field"
                  id="search"
                  ref={inputRef2}
                  // onChange={handleFilterChnage}
                  // value={filter?.search}
                />
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label
                  htmlFor="vendor_first_name"
                  className="all_lable_for_vendor_dashbaord"
                >
                  From :
                </label>
                <input
                  title="Enter search text."
                  type="date"
                  name="from_date"
                  className="form-control store_input_field_field"
                  id="from_date"
                  ref={inputRef3}
                  // onChange={handleFilterChnage}
                />
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label
                  htmlFor="vendor_first_name"
                  className="all_lable_for_vendor_dashbaord"
                >
                  To :
                </label>
                <input
                  title="Enter search text."
                  type="date"
                  name="to_date"
                  className="form-control store_input_field_field"
                  id="to_date"
                  ref={inputRef4}
                  // onChange={handleFilterChnage}
                />
              </div>
              <br />
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button
                  title="Enter search text."
                  type="submit"
                  className="form-control btn btn-primary "
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    border: "none",
                  }}
                >
                  Apply
                </button>
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    </>
  );
}
