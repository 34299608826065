import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React, { useState, useEffect, useRef } from "react";
import { Dropdown, Menu, Button, Drawer, Space } from "antd";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Pagination from "@mui/material/Pagination";
import { Link, useNavigate } from "react-router-dom";
import { Select } from "antd";
import PaymentTopNavbar from "../PaymentTopNavbar";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import Top_Navbar from "../../DashBaord/Top_navbar";
import moment from "moment";
import { ASSETS_BASE_URL } from "../../../config/constants";
import SkeltonList from "../../../components/SkeltonEffect/list";
import { dayCare as list } from "../../../controllers/payments/paymentsControllers";
import { getPage } from "../../../controllers/common";

function Payments(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

Payments.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 1) {
      setFilter((pre) => ({
        ...pre,
        from: moment().format("YYYY-MM-DD"),
      }));
      setSkip(0);
    } else {
      setFilter((pre) => ({
        ...pre,
        from: "",
      }));
      setSkip(0);
    }
  };

  /********************** Integration Stare from here **********************/
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const targetRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from: "",
    to: "",
    filter_by: "",
    search: "",
  }); //End

  const [showRequest, setShowRequest] = useState("");
  const [isFilterShow, setFilterShow] = useState(false);
  const handleFiterDrawer = () => {
    setFilterShow(!isFilterShow);
  };
  /*********************************************************
   *  This function is use to fetch order list
   *********************************************************/
  const getList = async () => {
    setListData([]);
    setIsLoading(true);
    const options = {
      type: "",
      condition: {
        ...(showRequest ? { status: showRequest } : null),
      },
      ...(filter ? { filter: filter } : null),
      select: {
        booking_seq_id: true,
        frisbeeRevenue: true,
        paidBy: true,
        txnNumber: true,
        paymentStatus: true,
        status: true,
        createdAt: true,
        paidAmount: true,
        total: true,
        vat: true,
        subTotal: true,
        discount: true,
        earnedtreats: true,
        delivery_type: true,
        delivery_charges: true,
        mobile: true,
        userData: true,
        shopId: true,
        branch: true,
      },
      sort: { _id: -1 },
      skip: SKIP ? SKIP : 0,
      limit: LIMIT ? LIMIT : 10,
    };
    const listData = await list(options);
    if (listData.status === true) {
      if (listData.result.length > 0) {
        setListData(listData?.result);
        setTotalPages(getPage(listData?.count));
        setIsLoading(false);
      } else {
        // setListData([{ order_seq_id: "No Data Found" }]);
        setTotalPages(0);
        setIsLoading(false);
      }
      const countOptions = {
        type: "count",
        condition: { ...(showRequest ? { status: showRequest } : null) },
        ...(filter ? { filter: filter } : null),
      };
      const count = await list(countOptions);
      setTotalPages(getPage(count?.result));
    } else {
      // setListData([{ order_seq_id: "No Data Found" }]);
      setTotalPages(0);
      setIsLoading(false);
    }
  }; //End

  /*********************************************************
   *  This function is for handle page change
   *********************************************************/
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setSkip((newPage - 1) * LIMIT);
    targetRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  /*********************************************************
   *  This function is ued for handle filter input chnage
   *********************************************************/
  const handleFilterReset = () => {
    try {
      setFilter("");
      inputRef1.current.value = "";
      inputRef2.current.value = "";
      inputRef3.current.value = "";
      inputRef4.current.value = "";
    } catch (error) {}
  };

  const handleFilterApply = (e) => {
    try {
      e.preventDefault();
      const form = new FormData(e.target);
      setFilter((pre) => ({
        ...pre,
        filter_by: form.get("filter_by") || "",
        search: form.get("search") || "",
        to: form.get("to_date") || "",
        from: form.get("from_date") || "",
      }));
      handleFiterDrawer();
    } catch (error) {}
  };

  /*********************************************************
   *  This function is load when page load and with dependency update
   *********************************************************/
  useEffect(() => {
    getList();
    targetRef.current.scrollIntoView({
      behavior: "smooth",
    });
    document.title = "Frisbee Vendor Admin || DayCarePayment List";
  }, [currentPage, showRequest, filter]);

  const dropdownMenu = (
    <Menu>
      <Menu.Item key="1">
        <Link to="/paymentsEditPage">
          <FaEdit />
          <span className="show_span_edit">Edit</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="2" onClick={togglePopUp}>
        <MdDelete /> <span className="show_span">Delete</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="container-fluid" ref={targetRef}>
        {/* <PaymentTopNavbar></PaymentTopNavbar> */}
        <Top_Navbar title={`DayCare Payments`} />
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="item_tabs_main_container"
                  >
                    <Tab
                      label="All"
                      className="tabs_main_contnet"
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="Recents"
                      className="tabs_main_contnet"
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </div>

                <div
                  className="filters_inventory_Rating_review"
                  onClick={handleFiterDrawer}
                >
                  <div className="inventory_filter_div">
                    <img
                      src={process.env.PUBLIC_URL + "./images/Filter.svg"}
                      alt=""
                      className="user_iconses"
                    />

                    <a href="#" className="filter_btn_invontry_rating_review">
                      Filters
                    </a>
                  </div>
                  {/* <div>
                  <Select
                    defaultValue="This Months"
                    style={{
                      width: 120,
                    }}
                    className="payment_select_tag_rating_review"
                    bordered={false}
                    options={[
                      {
                        value: "XYZ",
                        label: "XYZ",
                      },
                      {
                        value: "ABC",
                        label: "ABC",
                      },
                      {
                        value: "CDE",
                        label: "CDE",
                      },
                    ]}
                  />
                </div> */}
                </div>
              </div>
            </Box>
            {/* First Inventory */}
            <Payments
              // value={value}
              // index={0}
              className="main_container_payments"
            >
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 all_border_bottom_for_hide">
                      <h6 className="m-0 font-weight-bold common_inventory_heading">
                        Payments
                      </h6>
                    </div>
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Booking ID</th>
                              <th>Name</th>
                              <th>Transaction ID</th>
                              <th>Amount</th>
                              <th>Status</th>
                              <th>Payment Method</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ALLLISTDATA?.length > 0 ? (
                              ALLLISTDATA.map((items, index) => (
                                <>
                                  <tr key={index}>
                                    <td>
                                      {moment(`${items?.createdAt}`).format(
                                        "DD/MM/YYYY, h:m A"
                                      )}
                                    </td>
                                    <td>{items?.booking_seq_id}</td>
                                    <td>{items?.userData?.name}</td>
                                    <td>{items?.txnNumber}</td>
                                    <td>AED. {items?.paidAmount}</td>
                                    <td
                                      className={`${
                                        items.paymentStatus === "Pending"
                                          ? `pending`
                                          : ""
                                      }`}
                                    >
                                      {items.paymentStatus}
                                    </td>
                                    <td>{items?.paidBy}</td>
                                  </tr>
                                </>
                              ))
                            ) : isLoading ? (
                              <SkeltonList row={5} col={7} />
                            ) : (
                              <tr>
                                <td colspan="7" className="text-center">
                                  <img
                                    src="https://frisbee-llc.s3.me-central-1.amazonaws.com/images/nodata.jpg"
                                    width="50%"
                                  />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination
                            count={TOTALPAGES}
                            onChange={handlePageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Payments>
          </Box>
        </div>
        {/* Filter */}
        <Drawer
          title="DayCare Payment Filter"
          placement={`right`}
          width={500}
          onClose={handleFiterDrawer}
          open={isFilterShow}
          extra={
            <Space>
              <Button onClick={handleFiterDrawer}>Cancel</Button>
              <Button type="primary" onClick={handleFilterReset}>
                Reset
              </Button>
            </Space>
          }
        >
          <div className="responsive">
            <div className="row">
              <form id="filter_form" onSubmit={handleFilterApply}>
                <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <label
                    htmlFor="vendor_first_name"
                    className="all_lable_for_vendor_dashbaord"
                  >
                    Search Field :
                  </label>
                  <select
                    name="filter_by"
                    className="form-control store_input_field_field"
                    id="filter_by"
                    ref={inputRef1}
                  >
                    <option value="">Select search field</option>
                    <option
                      value="booking_seq_id"
                      selected={
                        filter?.filter_by === "booking_seq_id" ? true : false
                      }
                    >
                      Booking ID
                    </option>
                    <option
                      value="name"
                      selected={filter?.filter_by === "name" ? true : false}
                    >
                      Name
                    </option>
                    <option
                      value="txnNumber"
                      selected={
                        filter?.filter_by === "txnNumber" ? true : false
                      }
                    >
                      Transaction ID
                    </option>
                    <option
                      value="paidAmount"
                      selected={
                        filter?.filter_by === "paidAmount" ? true : false
                      }
                    >
                      Amount
                    </option>
                    <option
                      value="paymentStatus"
                      selected={
                        filter?.filter_by === "paymentStatus" ? true : false
                      }
                    >
                      Status
                    </option>
                    <option
                      value="paidBy"
                      selected={filter?.filter_by === "paidBy" ? true : false}
                    >
                      Payment Method
                    </option>
                  </select>
                </div>
                <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <label
                    htmlFor="vendor_first_name"
                    className="all_lable_for_vendor_dashbaord"
                  >
                    Search Text :
                  </label>
                  <input
                    title="Enter search text."
                    placeholder="Enter search text."
                    type="text"
                    name="search"
                    className="form-control store_input_field_field"
                    id="search"
                    ref={inputRef2}
                    // onChange={handleFilterChnage}
                    // value={filter?.search}
                  />
                </div>
                <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <label
                    htmlFor="vendor_first_name"
                    className="all_lable_for_vendor_dashbaord"
                  >
                    From :
                  </label>
                  <input
                    title="Enter search text."
                    type="date"
                    name="from_date"
                    className="form-control store_input_field_field"
                    id="from_date"
                    ref={inputRef3}
                    // onChange={handleFilterChnage}
                  />
                </div>
                <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <label
                    htmlFor="vendor_first_name"
                    className="all_lable_for_vendor_dashbaord"
                  >
                    To :
                  </label>
                  <input
                    title="Enter search text."
                    type="date"
                    name="to_date"
                    className="form-control store_input_field_field"
                    id="to_date"
                    ref={inputRef4}
                    // onChange={handleFilterChnage}
                  />
                </div>
                <br />
                <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <button
                    title="Enter search text."
                    type="submit"
                    className="form-control btn btn-primary "
                    style={{
                      backgroundColor: "black",
                      color: "white",
                      border: "none",
                    }}
                  >
                    Apply
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
}
